import React, { useState } from 'react';
import VideoThumbnail from '../../images/portfolio/FilmOgKino/video-thumbnail.jpg';
import Poster from '../../images/portfolio/FilmOgKino/poster.jpg';
import SkoleKinoRoed from '../../images/portfolio/FilmOgKino/logo-skolekino-rod.png';
import SkoleKinoSort from '../../images/portfolio/FilmOgKino/logo-skolekino-sort.png';
import PortfolioPage from '../../components/PortfolioPage';

const PortfolioFilmOgKino = () => {    
    return <PortfolioPage img={VideoThumbnail} url="https://www.youtube.com/embed/aQwNaecziu8"  title="Film og Kino" description={
      <div>
        Den Store Skolekinodagen holdes hvert år for skoler som kan tilbringe en dag på kino. 
        <br />
        Kinodagen tilrettelegger for faglig utvikling for kinoer over hele landet. 
        <br />
        The Branch produserte noen illustrasjoner og animasjoner for kampanjen i 2023. 
        <br />
        Vi redesignet også logoen.       
        <br />       
        <br />  
        <p>
        (2023)
        </p>
      </div>
    }>
         <div className=" w-full mt-32 lg:px-0">
            <div className="flex flex-wrap">
              <img className="max-w-full mb-12 mr-8 md:max-w-2xl" src={Poster}  />
              <div >
                <div>
                  <img src={SkoleKinoRoed} className="max-w-full md:max-w-md mb-8" />
                </div>
                <div>
                  <img src={SkoleKinoSort} className="max-w-full md:max-w-md" />
                </div>
           
              </div>
            </div>
            
          </div>
    </PortfolioPage>
}

export default PortfolioFilmOgKino;